import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layouts/Layout"
import { Grid, Col, AlignCenter, Media } from "../components/utilities"
import { ContactSection, Section } from "../components/elements"

const IndexGrid = styled(Grid)`
  ${Media.below.desktop`
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;
  `}
`

const IndexPage = ({ data, uri, ...props }) => {
  const node = data.allWordpressPage.edges[0].node
  return (
    <Layout uri={uri}>
      <Section>
        <IndexGrid>
          <Col>
            <h2 dangerouslySetInnerHTML={{ __html: node.title }}></h2>
            <div dangerouslySetInnerHTML={{ __html: node.content }}></div>
          </Col>
          {node.featured_media.source_url && (
            <Col>
              <AlignCenter>
                <img src={node.featured_media.source_url} />
              </AlignCenter>
            </Col>
          )}
        </IndexGrid>
      </Section>
      <ContactSection />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    allWordpressPage(filter: { path: { eq: "/" } }) {
      edges {
        node {
          title
          content
          featured_media {
            source_url
          }
        }
      }
    }
  }
`
